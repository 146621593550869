exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-casecard-template-tsx": () => import("./../../../src/templates/casecard-template.tsx" /* webpackChunkName: "component---src-templates-casecard-template-tsx" */),
  "component---src-templates-institutional-template-tsx": () => import("./../../../src/templates/institutional-template.tsx" /* webpackChunkName: "component---src-templates-institutional-template-tsx" */),
  "component---src-templates-product-template-tsx": () => import("./../../../src/templates/product-template.tsx" /* webpackChunkName: "component---src-templates-product-template-tsx" */),
  "component---src-templates-studio-template-tsx": () => import("./../../../src/templates/studio-template.tsx" /* webpackChunkName: "component---src-templates-studio-template-tsx" */)
}

