exports.shouldUpdateScroll = ({
    routerProps: { location },
    getSavedScrollPosition,
}) => {
    const { pathname, hash } = location;
    // list of routes for the scroll-to-top behavior
    const scrollToTopRoutes = [/^\/$/, /^\/samelab\//, /^\/hayat-finans/, /^\/kidokit/, /^\/nkolay/];
    if (scrollToTopRoutes.some(route => route.test(pathname))) {
        if (hash) {
            // If the new location has a hash, let the browser handle scrolling.
            return true;
        }
        setTimeout(() => window.scrollTo(0, 0), 0);
        return false;
    }
    return true;
};